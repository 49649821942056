import { Observable, Subject, of } from 'rxjs';
import { UtilNumbersHelper } from './numbers';


/**
 * Returns a list of all focusable HTML elements inside the passed root
 * @param root Element to find docusable elements in
 */
export function retrieveFocusableElements(root: HTMLElement): NodeListOf<HTMLElement> {
  return root.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  );
}


export function getFirstFocusableElement(element: HTMLElement): HTMLElement {
  const [first] = Array.from(retrieveFocusableElements(element));
  return first;
}

export function getComposedPath(element: Element): Element[] {

  const path: Element[] = [];

  let el = element as Element;

  while (el) {
    path.push(el);
    el = el.parentNode as Element;
  }

  return path;
}

export function getTextLengthOfElement(el: HTMLElement): {text: string; length: number;} {

  const css = window?.getComputedStyle(el);
  const rect = el.getBoundingClientRect();
  const text = el.innerText || '';

  const canvas = document.createElement('canvas');
  canvas.width = rect.width;
  canvas.height = rect.height;

  const c = canvas.getContext('2d');
  c.font = css?.fontSize + ' ' + css?.fontFamily;

  const length = c.measureText(text)?.width;

  return {text, length};
}

export class UtilElementHelper {

  static waitForChild<T extends Element = HTMLElement>(host: HTMLElement, selector: string): Observable<T> {

    const syncEl = host.querySelector(selector) as T;

    if (syncEl) {
      return of(syncEl);
    }

    const subj = new Subject<T>();

    const mutationObs = new MutationObserver(entries => {
      const res = host.querySelector(selector) as T;
      if (res) {
        mutationObs.disconnect();
        subj.next(res);
        subj.complete();
      }
    });

    mutationObs.observe(host, {childList:true, subtree: true});

    return subj.asObservable();
  }

  /**
   * Note: - Returns "cold" observable. - Completes when first subscriber unsubscribes
   */
  static onElementResize(el: HTMLElement) {

    const obs = new Observable<ResizeObserverEntry>(subscriber => {

      const resizeObserver = new ResizeObserver(entries => {
        subscriber.next(entries[0]);
      });

      resizeObserver.observe(el);

      // teardown logic called when one subscriber unsubscribes
      return () => {
        resizeObserver.disconnect();
        subscriber.complete();
      };

    });

    return obs;

  }

  /**
   * returns next focusable element relative to a given element.
   * returns null if the given element was not focusable
   */
  static getNextFocusableElement(focusableElement: HTMLElement, notContainer?: HTMLElement) {

    const arr = Array.from(retrieveFocusableElements(document.body));
    const oldi = arr.findIndex(_el => _el === focusableElement);

    if (oldi < 0) {
      return null;
    }

    let newi: number;
    let newEl: HTMLElement;
    let found = false;

    if (!notContainer) {
      newi = UtilNumbersHelper.getIncreasedNumberWithLoopingAround(oldi, 1, arr.length - 1, 0);
      newEl = arr[newi];
    } else {

      for (let j = 0; !found && j < arr.length; j++) {
        newi = UtilNumbersHelper.getIncreasedNumberWithLoopingAround(oldi, (1 + j), arr.length - 1, 0);
        newEl = arr[newi];
        if (!notContainer.contains(newEl)) {
          found = true;
        }
      }

    }

    return newEl;

  }
}

