import { AfterViewChecked, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { getFirstFocusableElement } from '../../functions/elements';
import { WindowRef } from '@spartacus/core';


export interface ActivateElementOptions {
  /**
   * A function, which describes how to get the target element from the host element
   * by default: it gets the first inside focusable element if it is not itself focusable
   */
  elementDelegateFunction?: (host: HTMLElement) => HTMLElement;
  /**
   * allowed events
   */
  events?: ('click' | 'keydown')[];
  allowedKeys?: ('Enter' | ' ' | 'Escape' | 'Tab')[];
  /**
   * the directive subscribe to the emitter and refreshes whenever it emits any kind of data
   */
  updateEmitter?: Observable<any>;
  /**
   * after a triggered activateTabElement event and the next one needs to pass a certain cooldown time (in ms) or
   * the particular one will be ignored.
   * Example use: Browsers dispatch a click event on a <button> even if the spacebar or Enter key was typed. This can cause
   * several event handler to trigger, especially if the focus were switched programmatically like it is when opening
   * a modal dialog.
   * default: 333
   */
  cooldownTime?: number;
  /**
   * possible to bind an observable. The Element receives the focus, everytime the observable triggers
   */
  focusTrigger?: Observable<any>;
}


@Directive({
  selector: '[activateElement]'
})
export class ActivateElementDirective implements AfterViewChecked, OnDestroy {

  private readonly elementRef = inject(ElementRef);
  private readonly windowRef = inject(WindowRef);

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers, no-magic-numbers
  private static defaultCooldownTime = 333;
  private static timeStampOfLastEvent = 0;

  private _options: ActivateElementOptions;
  private host: HTMLElement;
  private element: HTMLElement;
  private emitterSubscription: Subscription;
  private focusTriggerSubscription: Subscription;

  @Output()
  readonly activateElement = new EventEmitter<UIEvent>();

  @Input()
  set activateElementOptions(value: ActivateElementOptions) {
    this.refresh(this.respectOptionsDefaultValues(value));
  }

  get activateElementOptions(): ActivateElementOptions {
    return this._options;
  }

  ngAfterViewChecked(): void {
    this.refresh(this.respectOptionsDefaultValues(this.activateElementOptions));
  }

  ngOnDestroy() {
    this.unregister();
  }

  private handler = (event: UIEvent) => {

    const isAllowed = event instanceof KeyboardEvent ? (this.activateElementOptions.allowedKeys as string[]).includes(event.key) : true;

    const timeAfterLast = event.timeStamp - ActivateElementDirective.timeStampOfLastEvent;

    const enoughTimePassed = timeAfterLast > this.activateElementOptions.cooldownTime;

    if (isAllowed && enoughTimePassed) {
      ActivateElementDirective.timeStampOfLastEvent = event.timeStamp;
      this.activateElement.emit(event);
    }

    const isFocusedElementWritable = ['INPUT', 'TEXTAREA'].includes(this.windowRef.nativeWindow.document.activeElement.nodeName) || this.windowRef.nativeWindow.document.activeElement.getAttribute('contenteditable') === 'true';

    // prevent the keydown event of the spacebar to prevent scrolling down (but exclude if the focus is on typing fields so that the space key is not lost to these)
    if (event instanceof KeyboardEvent && event.type === 'keydown' && event.key === ' ' && !isFocusedElementWritable) {
      event.preventDefault();
    }
  };

  private refresh(options: ActivateElementOptions) {

    // to avoid recursion
    this._options = options;

    this.unregister();

    this.host = this.elementRef.nativeElement as HTMLElement;
    const hasTabIndex = !!(this.host?.tabIndex >= 0);
    this.element = hasTabIndex ? this.host : this.activateElementOptions.elementDelegateFunction(this.host);

    this.register();
  }

  private register() {
    if (this.element) {
      this.activateElementOptions.events.forEach(type => {
        this.element.addEventListener(type, this.handler);
      });
    }

    if (this.activateElementOptions?.focusTrigger) {
      this.focusTriggerSubscription = this.activateElementOptions.focusTrigger.subscribe(_ => {
        this.element.focus();
      });
    }

    this.emitterSubscription = this.activateElementOptions?.updateEmitter?.subscribe(_ => {
      this.refresh(this.activateElementOptions);
    });
  }

  private unregister() {
    this.emitterSubscription?.unsubscribe();
    this.activateElementOptions.events.forEach(type => {
      this.element?.removeEventListener(type, this.handler);
    });
    this.focusTriggerSubscription?.unsubscribe();
  }

  private respectOptionsDefaultValues(options: ActivateElementOptions): ActivateElementOptions {

    options = options || {};
    options.elementDelegateFunction = options.elementDelegateFunction || (host => getFirstFocusableElement(host) || host);
    options.events = options.events || ['click', 'keydown'];
    options.allowedKeys = options.allowedKeys || ['Enter', ' '];
    options.cooldownTime = options.cooldownTime ?? ActivateElementDirective.defaultCooldownTime;

    return options;
  }

}
